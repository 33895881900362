import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../css/template/webinar-post.scss"
import "../css/template/past-webinar-post.scss"
import BackgroundImage from "gatsby-background-image"
import CallbackForm from "../components/CallbackForm/CallbackForm"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { ApolloProvider } from "@apollo/client"
import { client } from "../hooks/apolloClient"
import GatedForm from "../components/GatedForm"

const PastHsWebinarPostTemplate = ({ data, pageContext }) => {
  const pageID = pageContext.id
  const [openCallback, setOpenCallback] = useState(false)
  const post = data?.post
  const form = { wpGfForm: data?.gatedForm, wp: data?.wp }
  const image = getImage(post?.featuredImage?.node?.localFile)
  const bgImage = convertToBgImage(image)
  const videodId = post?.pastWebinar?.youtubeLink.split("/").slice(-1)[0]
  const { gatedForm, formTitle, formDescription } = post?.gatedForm
  const [showForm, setShowForm] = useState(gatedForm)
  // const [hasFilledForm, setHasFilledForm] = useState()
  const [hasSubmittedForm, setHasSubmittedForm] = useState(false)
  useEffect(() => {
    // const storedHasFilledForm = localStorage.getItem(`${pageID}`)
    // const initialHasFilledForm = storedHasFilledForm === "true"

    // setHasFilledForm(initialHasFilledForm)

    if (gatedForm) {
      setShowForm(true)
    }
  }, [gatedForm])

  const handleFormSubmit = () => {
    // localStorage.setItem(`${pageID}`, "true")
    setShowForm(false)
    // setHasFilledForm(true)
    setHasSubmittedForm(true)
  }

  const successCallback = ({ data: formRes, reset }) => {
    // This will be called after the form is successfully submitted
    handleFormSubmit()
  }

  return (
    <ApolloProvider client={client}>
      <Layout>
        {showForm ? (
          <GatedForm
            data={form}
            formDescription={formDescription}
            formTitle={formTitle}
            successCallback={successCallback}
            pageTitle={post.title}
          />
        ) : (
          <>
            <div className="post-headline">
              <BackgroundImage className="post-cover" Tag="div" {...bgImage}>
                <div className="container">
                  <div
                    className="callback-button"
                    onClick={() => {
                      setOpenCallback(!openCallback)
                    }}
                  >
                    <p>Request a callback today »</p>
                  </div>
                  <div className="title-wrapper">
                    {post?.title && <h1>{parse(post?.title)}</h1>}
                  </div>
                </div>
                <CallbackForm open={openCallback} setOpen={setOpenCallback} />
              </BackgroundImage>
            </div>
            <div className="container main-post-wrapper">
              {post?.content && (
                <section className="main-post-content" itemProp="articleBody">
                  {parse(post?.content)}
                </section>
              )}
              <div className="webinar-registration">
                <div className="aspect-[816/481] post__youtube w-full h-auto relative rounded-[10px] overflow-hidden">
                  <div>
                    <iframe
                      src={`https://www.youtube.com/embed/${videodId}`}
                      height="400"
                      width="100%"
                      title={post.title || "youtube video"}
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                      className="responsive-iframe"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Layout>
    </ApolloProvider>
  )
}
export const Head = ({ data }) => (
  <Seo
    title={data?.seo?.seo?.title}
    description={data?.seo?.seo?.metaDesc}
    image={data?.seo?.featuredImage?.node?.sourceUrl}
    props={data?.seo?.seo}
  />
)

export default PastHsWebinarPostTemplate

export const pageQuery = graphql`
  query WebinarPostById($id: String!) {
    post: wpPastHealthSafetyWebinar(id: { eq: $id }) {
      content
      excerpt
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
      }
      title
      uri
      slug
      pastWebinar {
        youtubeLink
      }
      gatedForm {
        gatedForm
        formTitle
        formDescription
      }
    }
    seo: wpPastHealthSafetyWebinar(id: { eq: $id }) {
      featuredImage {
        node {
          sourceUrl
        }
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          srcSet
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          srcSet
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
    gatedForm: wpGfForm(databaseId: { eq: 14 }) {
      ...GravityFormFields
    }
    wp {
      ...GravityFormSettings
    }
  }
`
